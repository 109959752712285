<template>
    <div class="manyManual drag" v-draw :id="dynamicID">
             <div class="evaluate-header">
              {{titname}}
                <div class="evaluate-header-icon" @click="closeCompon">
             <img src="@/assets/images/close.png" alt="">
      </div>
     </div>
     <div class="manual-con flex">
         <div class="manual_list"
         v-for="(item, index) in ManualList" :key="index"
         >
          <div class="manual-con-tit">{{item.name}}</div>
             <div class="manual-con-p">
         <el-slider v-model="item.manualP"
        :format-tooltip="formatTooltip"
        disabled
         ></el-slider>
       <div class="manual-con-text flex">
           <div>关</div>
           <div style="margin-left:auto">开</div>
           </div>     
    </div>
         <div class="manual-con-o manual-con-p">
         <el-slider v-model="item.manualO"
        :format-tooltip="formatTooltip1"
         disabled
         ></el-slider>
       <div class="manual-con-text flex">
           <div>关</div>
           <div style="margin-left:auto">开</div>
           </div>     
    </div>
     <div class="manual-con-type flex alicn-cen">
        <div class="type-p">
           <span class="">P</span>
           <span class="type-p-data">{{item.num}}</span>
        </div>
        <div class="type-o">
            <span class="type-p-data">{{item.num1}}</span>
             <span class="">O</span>
        </div>
    </div>
        <div class="manual-con-btn flex">
        <div class="pzd" :class="{oactive:1==infoList[item.node1].RM}" @click="isDisable ? downZ(1,item.node1) : ''">自动</div>
        <div class="pzd" :class="{ oactive:1!=infoList[item.node1].RM}" @click="isDisable ? downZ(0,item.node1) : ''">手动</div>
    </div>
        <div class="manual-con-add flex">
        <div class="add-double-left"
        @mouseenter="enter(1,item.node1)" @mouseleave="leave(1,item.node1)"
        :style="currindex== 1 && str == item.node1 ? activStyle :''"
        @click="isDisable ? numberjian(5,infoList[item.node1].AV,1,item.manualP,item.manualO,item.node1,index) : ''"
        >
            
            <span class="el-icon-caret-left" :style="currindex== 1 && str == item.node1 ? textcololr :''"></span>
            <span class="el-icon-caret-left el-icon-caret-left2" :style="currindex== 1 && str == item.node1 ? textcololr :''"></span>
        </div>
        <div class="add-double-cen flex align-cen">
            <div class="add-double-cen-btn" 
             @mouseenter="enter(2,item.node1)" @mouseleave="leave(2,item.node1)"
            :style="currindex== 2 && str == item.node1 ? activStyle :''"
            @click="isDisable ? numberjian(1,infoList[item.node1].AV,2,item.manualP,item.manualO,item.node1,index) : ''"
            >
               <span class="el-icon-caret-left" :style="currindex== 2 && str == item.node1 ? textcololr :''"></span> 
            </div>
            <div class="add-double-cen-btn" 
             @mouseenter="enter(3,item.node1)" @mouseleave="leave(3,item.node1)"
            :style="currindex==3 && str == item.node1 ? activStyle :''"
            @click="isDisable ? numberAdd(1,infoList[item.node1].AV,3,item.manualP,item.manualO,item.node1,index) : ''">
               <span class="el-icon-caret-right" :style="currindex==3 && str == item.node1 ? textcololr :''"></span> 
            </div>
        </div>
         <div class="add-double-left add-double-right" 
           @mouseenter="enter(4,item.node1)" @mouseleave="leave(4,item.node1)"
            :style="currindex==4 && str == item.node1 ? activStyle :''"
          @click="isDisable ? numberAdd(5,infoList[item.node1].AV,4,item.manualP,item.manualO,item.node1,index) : ''">
            <span class="el-icon-caret-right el-icon-caret-left1" :style="currindex==4 && str == item.node1 ? textcololr :''"></span>
            <span class="el-icon-caret-right" :style="currindex==4 && str == item.node1 ? textcololr :''"></span>
        </div>
    </div>
         </div>
     </div>
    </div>
</template>
<script>
import { downmanAction } from "@/api/index/index"
import { downAction } from "@/api/index/index"
export default {
    name:'manyManual',
        props: {
            infoList:{
            type:Object,
            default: {} // 默认值
            },
            titname:[String, Number], //tit名字
            numM:[String, Number], //手操器数量
        },
        data(){
            return {
                dynamicID:'',
                ManualList:[],
                projectData:{},
                current:0,
                 isDisable:true,
                  activStyle:'',
                 currindex:'',
                 str:''
            }
        },
          watch: {
    infoList: {
            handler (n, o) {
            this.infoList = n
            },
            deep: true // 深度监听父组件传过来对象变化
            },
             titname: {
            handler (n, o) {
            this.titname = n
            },
            deep: true // 深度监听父组件传过来对象变化
            },
            numM: {
            handler (n, o) {
            this.numM = n
            },
            deep: true // 深度监听父组件传过来对象变化
            },
   },
   created(){
          this.projectData = JSON.parse(localStorage.getItem("deviceType"))
       if(this.numM == 6 && this.titname == "CFB_MANGM"){
        this.dynamicID = 'manyManual'
        //    给煤手操器
          this.ManualList = [
              {
                  manualP:parseInt(this.infoList.MCSSOURCE.KF_GM1_B),
                  num:this.infoList.MCSSOURCE.KF_GM1_B,
                  mark:'KF_GM1_B',
                  node:'MCSSOURCE',
                  name:'1#给煤机手操器',
                  manualO:parseInt(this.infoList.MCSMAN__p__MAN1.AV),
                  num1:this.infoList.MCSMAN__p__MAN1.AV,
                  mark1:'AV',
                  node1:'MCSMAN__p__MAN1'
              },
                {
                  manualP:parseInt(this.infoList.MCSSOURCE.KF_GM2_B),
                  num:this.infoList.MCSSOURCE.KF_GM2_B,
                  mark:'KF_GM2_B',
                  node:'MCSSOURCE',
                  name:'2#给煤机手操器',
                  manualO:parseInt(this.infoList.MCSMAN__p__MAN2.AV),
                  num1:this.infoList.MCSMAN__p__MAN2.AV,
                  mark1:'AV',
                  node1:'MCSMAN__p__MAN2'
              },
                {
                  manualP:parseInt(this.infoList.MCSSOURCE.KF_GM3_B),
                  num:this.infoList.MCSSOURCE.KF_GM3_B,
                  mark:'KF_GM3_B',
                  node:'MCSSOURCE',
                  name:'3#给煤机手操器',
                  manualO:parseInt(this.infoList.MCSMAN__p__MAN3.AV),
                  num1:this.infoList.MCSMAN__p__MAN3.AV,
                  mark1:'AV',
                  node1:'MCSMAN__p__MAN3'
              },{
                  manualP:parseInt(this.infoList.MCSSOURCE.KF_GM4_B),
                  num:this.infoList.MCSSOURCE.KF_GM4_B,
                  mark:'KF_GM4_B',
                  node:'MCSSOURCE',
                  name:'4#给煤机手操器',
                  manualO:parseInt(this.infoList.MCSMAN__p__MAN4.AV),
                  num1:this.infoList.MCSMAN__p__MAN4.AV,
                  mark1:'AV',
                  node1:'MCSMAN__p__MAN4'
              },{
                  manualP:parseInt(this.infoList.MCSSOURCE.KF_GM5_B),
                  num:this.infoList.MCSSOURCE.KF_GM5_B,
                  mark:'KF_GM5_B',
                  node:'MCSSOURCE',
                  name:'5#给煤机手操器',
                  manualO:parseInt(this.infoList.MCSMAN__p__MAN23.AV),
                  num1:this.infoList.MCSMAN__p__MAN23.AV,
                  mark1:'AV',
                  node1:'MCSMAN__p__MAN23'
              },{
                  manualP:parseInt(this.infoList.MCSSOURCE.KF_GM6_B),
                  num:this.infoList.MCSSOURCE.KF_GM6_B,
                  mark:'KF_GM6_B',
                  node:'MCSSOURCE',
                  name:'6#给煤机手操器',
                  manualO:parseInt(this.infoList.MCSMAN__p__MAN24.AV),
                  num1:this.infoList.MCSMAN__p__MAN24.AV,
                  mark1:'AV',
                  node1:'MCSMAN__p__MAN24'
              }
          ]
       }else if(this.numM == 2 && this.titname == "CFB_MANECFY"){
            this.dynamicID = 'manyManual2'
             this.ManualList = [
                 {
                  manualP:parseInt(this.infoList.MCSSOURCE.KF_SW1_B),
                  num:this.infoList.MCSSOURCE.KF_SW1_B,
                  mark:'KF_SW1_B',
                  node:'MCSSOURCE',
                  name:'上二次风箱调门手操器',
                  manualO:parseInt(this.infoList.MCSMAN__p__MAN31.AV),
                  num1:this.infoList.MCSMAN__p__MAN31.AV,
                  mark1:'AV',
                  node1:'MCSMAN__p__MAN31'
              },   {
                  manualP:parseInt(this.infoList.MCSSOURCE.KF_SW2_B),
                  num:this.infoList.MCSSOURCE.KF_SW2_B,
                  mark:'KF_SW2_B',
                  node:'MCSSOURCE',
                  name:'下二次风箱调门手操器',
                  manualO:parseInt(this.infoList.MCSMAN__p__MAN32.AV),
                  num1:this.infoList.MCSMAN__p__MAN32.AV,
                  mark1:'AV',
                  node1:'MCSMAN__p__MAN32'
              }
             ]
       }else if(this.numM ==4 && this.titname == "CFB_MANCZ"){
            this.dynamicID = 'manyManual4'
              this.ManualList = [
                 {
                  manualP:parseInt(this.infoList.MCSSOURCE.KF_CZ1_B),
                  num:this.infoList.MCSSOURCE.KF_CZ1_B,
                  mark:'KF_CZ1_B',
                  node:'MCSSOURCE',
                  name:'1#冷渣机手操器',
                  manualO:parseInt(this.infoList.MCSMAN__p__MAN13.AV),
                  num1:this.infoList.MCSMAN__p__MAN13.AV,
                  mark1:'AV',
                  node1:'MCSMAN__p__MAN13'
              },{
                  manualP:parseInt(this.infoList.MCSSOURCE.KF_CZ2_B),
                  num:this.infoList.MCSSOURCE.KF_CZ2_B,
                  mark:'KF_CZ2_B',
                  node:'MCSSOURCE',
                  name:'2#冷渣机手操器',
                  manualO:parseInt(this.infoList.MCSMAN__p__MAN14.AV),
                  num1:this.infoList.MCSMAN__p__MAN14.AV,
                  mark1:'AV',
                  node1:'MCSMAN__p__MAN14'
              }, {
                  manualP:parseInt(this.infoList.MCSSOURCE.KF_CZ3_B),
                  num:this.infoList.MCSSOURCE.KF_CZ3_B,
                  mark:'KF_CZ3_B',
                  node:'MCSSOURCE',
                  name:'3#冷渣机手操器',
                  manualO:parseInt(this.infoList.MCSMAN__p__MAN28.AV),
                  num1:this.infoList.MCSMAN__p__MAN28.AV,
                  mark1:'AV',
                  node1:'MCSMAN__p__MAN28'
              }, {
                  manualP:parseInt(this.infoList.MCSSOURCE.KF_CZ4_B),
                  num:this.infoList.MCSSOURCE.KF_CZ4_B,
                  mark:'KF_CZ4_B',
                  node:'MCSSOURCE',
                  name:'4#冷渣机手操器',
                  manualO:parseInt(this.infoList.MCSMAN__p__MAN29.AV),
                  num1:this.infoList.MCSMAN__p__MAN29.AV,
                  mark1:'AV',
                  node1:'MCSMAN__p__MAN29'
              }
             ]
       }else if(this.numM ==4 && this.titname == "CFB_MANQWZ"){
            this.dynamicID = 'manyManual4'
                 this.ManualList = [
                 {
                  manualP:parseInt(this.infoList.MCSSOURCE.KF_ZR1_B),
                  num:this.infoList.MCSSOURCE.KF_ZR1_B,
                  mark:'KF_ZR1_B',
                  node:'MCSSOURCE',
                  name:'再热一级左减温手操器',
                  manualO:parseInt(this.infoList.MCSMAN__p__MAN33.AV),
                  num1:this.infoList.MCSMAN__p__MAN33.AV,
                  mark1:'AV',
                  node1:'MCSMAN__p__MAN33'
              },{
                  manualP:parseInt(this.infoList.MCSSOURCE.KF_ZR2_B),
                  num:this.infoList.MCSSOURCE.KF_ZR2_B,
                  mark:'KF_ZR2_B',
                  node:'MCSSOURCE',
                  name:'再热一级右减温手操器',
                  manualO:parseInt(this.infoList.MCSMAN__p__MAN34.AV),
                  num1:this.infoList.MCSMAN__p__MAN34.AV,
                  mark1:'AV',
                  node1:'MCSMAN__p__MAN34'
              }, {
                  manualP:parseInt(this.infoList.MCSSOURCE.KF_ZR3_B),
                  num:this.infoList.MCSSOURCE.KF_ZR3_B,
                  mark:'KF_ZR3_B',
                  node:'MCSSOURCE',
                  name:'再热二级左减温手操器',
                  manualO:parseInt(this.infoList.MCSMAN__p__MAN35.AV),
                  num1:this.infoList.MCSMAN__p__MAN35.AV,
                  mark1:'AV',
                  node1:'MCSMAN__p__MAN35'
              }, {
                  manualP:parseInt(this.infoList.MCSSOURCE.KF_ZR4_B),
                  num:this.infoList.MCSSOURCE.KF_ZR4_B,
                  mark:'KF_ZR4_B',
                  node:'MCSSOURCE',
                  name:'再热二级右减温手操器',
                  manualO:parseInt(this.infoList.MCSMAN__p__MAN36.AV),
                  num1:this.infoList.MCSMAN__p__MAN36.AV,
                  mark1:'AV',
                  node1:'MCSMAN__p__MAN36'
              }
             ]
       }else if(this.numM ==4 && this.titname == "CFB_MANQW"){
             this.dynamicID = 'manyManual4'
                 this.ManualList = [
                 {
                  manualP:parseInt(this.infoList.MCSSOURCE.KF_QW1_B),
                  num:this.infoList.MCSSOURCE.KF_QW1_B,
                  mark:'KF_QW1_B',
                  node:'MCSSOURCE',
                  name:'一级左减温手操器',
                  manualO:parseInt(this.infoList.MCSMAN__p__MAN7.AV),
                  num1:this.infoList.MCSMAN__p__MAN7.AV,
                  mark1:'AV',
                  node1:'MCSMAN__p__MAN7'
              },{
                  manualP:parseInt(this.infoList.MCSSOURCE.KF_QW2_B),
                  num:this.infoList.MCSSOURCE.KF_QW2_B,
                  mark:'KF_QW2_B',
                  node:'MCSSOURCE',
                  name:'一级右减温手操器',
                  manualO:parseInt(this.infoList.MCSMAN__p__MAN8.AV),
                  num1:this.infoList.MCSMAN__p__MAN8.AV,
                  mark1:'AV',
                  node1:'MCSMAN__p__MAN8'
              }, {
                  manualP:parseInt(this.infoList.MCSSOURCE.KF_QW3_B),
                  num:this.infoList.MCSSOURCE.KF_QW3_B,
                  mark:'KF_QW3_B',
                  node:'MCSSOURCE',
                  name:'二级左减温手操器',
                  manualO:parseInt(this.infoList.MCSMAN__p__MAN9.AV),
                  num1:this.infoList.MCSMAN__p__MAN9.AV,
                  mark1:'AV',
                  node1:'MCSMAN__p__MAN9'
              }, {
                  manualP:parseInt(this.infoList.MCSSOURCE.KF_QW4_B),
                  num:this.infoList.MCSSOURCE.KF_QW4_B,
                  mark:'KF_QW4_B',
                  node:'MCSSOURCE',
                  name:'二级右减温手操器',
                  manualO:parseInt(this.infoList.MCSMAN__p__MAN10.AV),
                  num1:this.infoList.MCSMAN__p__MAN10.AV,
                  mark1:'AV',
                  node1:'MCSMAN__p__MAN10'
              }
             ]
       }else if(this.numM ==2 && this.titname == "CFB_MANGS"){
                  this.dynamicID = 'manyManual2'
                 this.ManualList = [
                 {
                  manualP:parseInt(this.infoList.MCSSOURCE.KF_SW1_B),
                  num:this.infoList.MCSSOURCE.KF_SW1_B,
                  mark:'KF_SW1_B',
                  node:'MCSSOURCE',
                  name:'主给水手操器',
                  manualO:parseInt(this.infoList.MCSMAN__p__MAN5.AV),
                  num1:this.infoList.MCSMAN__p__MAN5.AV,
                  mark1:'AV',
                  node1:'MCSMAN__p__MAN5'
              },{
                  manualP:parseInt(this.infoList.MCSSOURCE.KF_SW2_B),
                  num:this.infoList.MCSSOURCE.KF_SW2_B,
                  mark:'KF_SW2_B',
                  node:'MCSSOURCE',
                  name:'副给水手操器',
                  manualO:parseInt(this.infoList.MCSMAN__p__MAN6.AV),
                  num1:this.infoList.MCSMAN__p__MAN6.AV,
                  mark1:'AV',
                  node1:'MCSMAN__p__MAN6'
              }
             ]
       }else if(this.numM ==4 && this.titname == "CFB_MANECF"){
             this.dynamicID = 'manyManual4'
                 this.ManualList = [
                 {
                  manualP:parseInt(this.infoList.MCSSOURCE.KF_ECFBP1_B),
                  num:this.infoList.MCSSOURCE.KF_ECFBP1_B,
                  mark:'KF_ECFBP1_B',
                  node:'MCSSOURCE',
                  name:'1#二次风变频手操器',
                  manualO:parseInt(this.infoList.MCSMAN__p__MAN17.AV),
                  num1:this.infoList.MCSMAN__p__MAN17.AV,
                  mark1:'AV',
                  node1:'MCSMAN__p__MAN17'
              },{
                  manualP:parseInt(this.infoList.MCSSOURCE.KF_ECFDB1_B),
                  num:this.infoList.MCSSOURCE.KF_ECFDB1_B,
                  mark:'KF_ECFDB1_B',
                  node:'MCSSOURCE',
                  name:'1#二次风挡板手操器',
                  manualO:parseInt(this.infoList.MCSMAN__p__MAN18.AV),
                  num1:this.infoList.MCSMAN__p__MAN18.AV,
                  mark1:'AV',
                  node1:'MCSMAN__p__MAN18'
              }, {
                  manualP:parseInt(this.infoList.MCSSOURCE.KF_ECFBP2_B),
                  num:this.infoList.MCSSOURCE.KF_ECFBP2_B,
                  mark:'KF_ECFBP2_B',
                  node:'MCSSOURCE',
                  name:'2#二次风变频手操器',
                  manualO:parseInt(this.infoList.MCSMAN__p__MAN30.AV),
                  num1:this.infoList.MCSMAN__p__MAN30.AV,
                  mark1:'AV',
                  node1:'MCSMAN__p__MAN30'
              }, {
                  manualP:parseInt(this.infoList.MCSSOURCE.KF_ECFDB2_B),
                  num:this.infoList.MCSSOURCE.KF_ECFDB2_B,
                  mark:'KF_ECFDB2_B',
                  node:'MCSSOURCE',
                  name:'2#二次风挡板手操器',
                  manualO:parseInt(this.infoList.MCSMAN__p__MAN26.AV),
                  num1:this.infoList.MCSMAN__p__MAN26.AV,
                  mark1:'AV',
                  node1:'MCSMAN__p__MAN26'
              }
             ]
       }else if(this.numM ==4 && this.titname == "CFB_MANYCF"){
             this.dynamicID = 'manyManual4'
                 this.ManualList = [
                 {
                  manualP:parseInt(this.infoList.MCSSOURCE.KF_YCFBP_B),
                  num:this.infoList.MCSSOURCE.KF_YCFBP_B,
                  mark:'KF_YCFBP_B',
                  node:'MCSSOURCE',
                  name:'1#一次风变频手操器',
                  manualO:parseInt(this.infoList.MCSMAN__p__MAN15.AV),
                  num1:this.infoList.MCSMAN__p__MAN15.AV,
                  mark1:'AV',
                  node1:'MCSMAN__p__MAN15'
              },{
                  manualP:parseInt(this.infoList.MCSSOURCE.KF_YCFDB_B),
                  num:this.infoList.MCSSOURCE.KF_YCFDB_B,
                  mark:'KF_YCFDB_B',
                  node:'MCSSOURCE',
                  name:'1#一次风挡板手操器',
                  manualO:parseInt(this.infoList.MCSMAN__p__MAN16.AV),
                  num1:this.infoList.MCSMAN__p__MAN16.AV,
                  mark1:'AV',
                  node1:'MCSMAN__p__MAN16'
              }, {
                  manualP:parseInt(this.infoList.MCSSOURCE.KF_YCFBP2_B),
                  num:this.infoList.MCSSOURCE.KF_YCFBP2_B,
                  mark:'KF_YCFBP2_B',
                  node:'MCSSOURCE',
                  name:'2#一次风变频手操器',
                  manualO:parseInt(this.infoList.MCSMAN__p__MAN27.AV),
                  num1:this.infoList.MCSMAN__p__MAN27.AV,
                  mark1:'AV',
                  node1:'MCSMAN__p__MAN27'
              }, {
                  manualP:parseInt(this.infoList.MCSSOURCE.KF_YCFDB2_B),
                  num:this.infoList.MCSSOURCE.KF_YCFDB2_B,
                  mark:'KF_YCFDB2_B',
                  node:'MCSSOURCE',
                  name:'2#一次风挡板手操器',
                  manualO:parseInt(this.infoList.MCSMAN__p__MAN25.AV),
                  num1:this.infoList.MCSMAN__p__MAN25.AV,
                  mark1:'AV',
                  node1:'MCSMAN__p__MAN25'
              }
             ]
       }else if(this.numM ==4 && this.titname == "CFB_MANYF"){
             this.dynamicID = 'manyManual4'
                 this.ManualList = [
                 {
                  manualP:parseInt(this.infoList.MCSSOURCE.KF_YFBP1_B),
                  num:this.infoList.MCSSOURCE.KF_YFBP1_B,
                  mark:'KF_YFBP1_B',
                  node:'MCSSOURCE',
                  name:'1#引风变频手操器',
                  manualO:parseInt(this.infoList.MCSMAN__p__MAN19.AV),
                  num1:this.infoList.MCSMAN__p__MAN19.AV,
                  mark1:'AV',
                  node1:'MCSMAN__p__MAN19'
              },{
                  manualP:parseInt(this.infoList.MCSSOURCE.KF_YFDB1_B),
                  num:this.infoList.MCSSOURCE.KF_YFDB1_B,
                  mark:'KF_YFDB1_B',
                  node:'MCSSOURCE',
                  name:'1#引风挡板手操器',
                  manualO:parseInt(this.infoList.MCSMAN__p__MAN21.AV),
                  num1:this.infoList.MCSMAN__p__MAN21.AV,
                  mark1:'AV',
                  node1:'MCSMAN__p__MAN21'
              }, {
                  manualP:parseInt(this.infoList.MCSSOURCE.KF_YFBP2_B),
                  num:this.infoList.MCSSOURCE.KF_YFBP2_B,
                  mark:'KF_YFBP2_B',
                  node:'MCSSOURCE',
                  name:'2#引风变频手操器',
                  manualO:parseInt(this.infoList.MCSMAN__p__MAN20.AV),
                  num1:this.infoList.MCSMAN__p__MAN27.AV,
                  mark1:'AV',
                  node1:'MCSMAN__p__MAN27'
              }, {
                  manualP:parseInt(this.infoList.MCSSOURCE.KF_YFDB2_B),
                  num:this.infoList.MCSSOURCE.KF_YFDB2_B,
                  mark:'KF_YFDB2_B',
                  node:'MCSSOURCE',
                  name:'2#引风挡板手操器',
                  manualO:parseInt(this.infoList.MCSMAN__p__MAN22.AV),
                  num1:this.infoList.MCSMAN__p__MAN22.AV,
                  mark1:'AV',
                  node1:'MCSMAN__p__MAN25'
              }
             ]
       }else if(this.numM ==2 && this.titname == "CFB_MANTX"){
            this.dynamicID = 'manyManual2'
             this.ManualList = [
                 {
                  manualP:parseInt(this.infoList.MCSSOURCE.KF_TX_B),
                  num:this.infoList.MCSSOURCE.KF_TX_B,
                  mark:'KF_TX_B',
                  node:'MCSSOURCE',
                  name:'氨水调节阀手操器',
                  manualO:parseInt(this.infoList.MCSMAN__p__MAN38.AV),
                  num1:this.infoList.MCSMAN__p__MAN38.AV,
                  mark1:'AV',
                  node1:'MCSMAN__p__MAN38'
              }, {
                  manualP:parseInt(this.infoList.MCSSOURCE.KF_CYS_B),
                  num:this.infoList.MCSSOURCE.KF_CYS_B,
                  mark:'KF_CYS_B',
                  node:'MCSSOURCE',
                  name:'稀释水调节阀手操器',
                  manualO:parseInt(this.infoList.MCSMAN__p__MAN39.AV),
                  num1:this.infoList.MCSMAN__p__MAN39.AV,
                  mark1:'AV',
                  node1:'MCSMAN__p__MAN39'
              }
             ]
       }else if(this.numM ==2 && this.titname == "CFB_MANTL"){
            this.dynamicID = 'manyManual2'
             this.ManualList = [
                 {
                  manualP:parseInt(this.infoList.MCSSOURCE.KF_SHS_B),
                  num:this.infoList.MCSSOURCE.KF_SHS_B,
                  mark:'KF_SHS_B',
                  node:'MCSSOURCE',
                  name:'炉内脱硫石灰石手操器',
                  manualO:parseInt(this.infoList.MCSMAN__p__MAN40.AV),
                  num1:this.infoList.MCSMAN__p__MAN40.AV,
                  mark1:'AV',
                  node1:'MCSMAN__p__MAN40'
              }, {
                  manualP:parseInt(this.infoList.MCSSOURCE.KF_TL_B),
                  num:this.infoList.MCSSOURCE.KF_TL_B,
                  mark:'KF_TL_B',
                  node:'MCSSOURCE',
                  name:'湿法脱硫石灰浆液手操器',
                  manualO:parseInt(this.infoList.MCSMAN__p__MAN37.AV),
                  num1:this.infoList.MCSMAN__p__MAN37.AV,
                  mark1:'AV',
                  node1:'MCSMAN__p__MAN37'
              }
             ]
       }else if(this.numM ==2 && this.titname == "CFB_MANFL"){
            this.dynamicID = 'manyManual2'
             this.ManualList = [
                 {
                  manualP:parseInt(this.infoList.MCSSOURCE.KF_FL1_B),
                  num:this.infoList.MCSSOURCE.KF_FL1_B,
                  mark:'KF_FL1_B',
                  node:'MCSSOURCE',
                  name:'主给水手操器',
                  manualO:parseInt(this.infoList.MCSMAN__p__MAN11.AV),
                  num1:this.infoList.MCSMAN__p__MAN11.AV,
                  mark1:'AV',
                  node1:'MCSMAN__p__MAN11'
              }, {
                  manualP:parseInt(this.infoList.MCSSOURCE.KF_FL2_B),
                  num:this.infoList.MCSSOURCE.KF_FL2_B,
                  mark:'KF_FL2_B',
                  node:'MCSSOURCE',
                  name:'副给水手操器',
                  manualO:parseInt(this.infoList.MCSMAN__p__MAN12.AV),
                  num1:this.infoList.MCSMAN__p__MAN12.AV,
                  mark1:'AV',
                  node1:'MCSMAN__p__MAN12'
              }
             ]
       }
       
   },
   methods:{
        // 下边按钮增减
        numberAdd(num,nownum,action,left,right,node,i){
            this.isDisable = false
            // this.ManualList[i].manualO = right + num
            // left  +=num
            // right  +=num
            let newnum = parseFloat(nownum) + num
            this.infoList[node].AV = newnum.toFixed(2) 
                downmanAction({
                device:this.projectData.project,
                mark:'AV',
                node:node,
                value: this.infoList[node].AV,
                type: "FLOAT",
                action:action,
                cp_type:this.projectData.type
				})
            .then(res => {
              if(res.info.res == 1){
                this.isDisable = true
                 this.ManualList[i].num1 = newnum.toFixed(2) 
                   this.ManualList[i].manualO = right + num
              }else{
                this.$message.error('出错了');
                 this.isDisable = true
                  this.ManualList[i].num1 = parseFloat(nownum)
                   this.ManualList[i].manualO = parseFloat(nownum)
              }
            })
				.catch(err => {
          this.$message.error('下置失败');
           this.isDisable = true
            this.infoList[node].AV =parseFloat(nownum)
           this.ManualList[i].manualO  = parseFloat(nownum)
				})
            
        },
        numberjian(num,nownum,action,left,right,node,i){
            if(right <= 0 || nownum<=0){
                return
            }else{
             this.isDisable = false
            let newnum1 = parseFloat(nownum) - num
            this.infoList[node].AV = newnum1.toFixed(2)
                   downmanAction({
                device:this.projectData.project,
                mark:"AV",
                node:node,
                value: nownum,
                type: "FLOAT",
                action:action,
                cp_type:this.projectData.type
				})
            .then(res => {
              if(res.info.res == 1){
                this.isDisable = true
                 this.ManualList[i].num1 = newnum1.toFixed(2) 
                this.ManualList[i].manualO = right - num
              }else{
                this.$message.error('出错了');
                 this.isDisable = true
                  this.infoList[node].AV = parseFloat(nownum)
                 this.ManualList[i].manualO = parseFloat(nownum)
              }
            })
				.catch(err => {
          this.$message.error('下置失败');
           this.isDisable = true
            this.infoList[node].AV = parseFloat(nownum)
          this.ManualList[i].manualO = parseFloat(nownum)
				})
            if(this.infoList[node].AV<=100){
               left = parseInt(this.infoList[node].AV)
               if(this.infoList[node].AV <=0){
               this.infoList[node].AV = 0.00
                this.ManualList[i].manualO = 0
               }
            }
            }
        },
           // 移入移出
                enter(index,val){
                this.currindex = index
                this.str = val
                this.activStyle = "background:#0ef7ff20"
                this.textcololr = "color:#0ef7ff"
                },
             leave(index){
                 this.currindex = index
                  this.activStyle = "background:#02264490"
                this.textcololr = "color:#001a2b"
                },
        // 自动手动
        downZ(val,node){
              	downAction({
                device:this.projectData.project,
                mark:'RM',
                node:node,
                value: val,
                cp_type:this.projectData.type
				})
            .then(res => {
              if(res.info.res == 1){
                 this.infoList[node].RM=val;
              }else{
                this.$message.error('出错了');
              }
            })
				.catch(err => {
          this.$message.error('下置失败');
				})
        },
         closeCompon(){
           this.$emit('sendStatus', 'manyManual',false)
        },
        formatTooltip(val){
            return val
        },
         formatTooltip1(val){
            return val
        }
   }
}
</script>
<style lang="scss" scoped>
.manyManual{
       background: url("~@/assets/images/anquanshezhiditu.png") no-repeat 0 0;
     background-size: 100% 100%;
     cursor:move;
     border-radius: 5px;
        .evaluate-header {
    height: 5vh;
    line-height: 5vh;
    text-align: center;
    color: #0ef7ff;
    font-size: 3vh;
    position: relative;
    border-radius: 5px 5px 0 0;
    padding-top: 1.4vh;
    .evaluate-header-icon {
      width: 2vw;
      height: 2vh;
      position: absolute;
      right: 1vw;
      margin-top:-6vh;
      img{
        cursor: pointer;
      }
    }
  }
  .manual-con{      
      .manual_list{
          width: 30vw;
          height: 37vh;
             border-radius: 5px;
      background: url('~@/assets/images/shoucaoqi.png') no-repeat 0 0;
      background-size: 100% 100%;
        .manual-con-tit{
            font-size: 1vw;
            color: #d5fffe;
            margin: 5px 10px;
            letter-spacing:1px;
           text-align: center;
         }
              .manual-con-p{
         background: #014662;
         border-radius: 6px;
         height: 7vh;
         margin: 5px 10px;
         width: 29vw;
         
     }
     .manual-con-text{
         font-size: .8vw;
         padding: 0 10px;
          color: #6acafb;
          line-height: 20px;

     }
     .manual-con-type{
         padding: 0px 10px;
          font-size: 1vw;
           color: #6acafb;
           width: 29vw;
          .type-p-data{
                 display: inline-block;
                 background: #001a2a;
                  height: 3vh;
                 margin-left: 20px;
                 color: #1c9bfb;
                 width: 10vw;
                 line-height: 3vh;
                 text-align: center;
                 font-size: 1vw;
                
             }
         .type-o{
             margin-left: auto;
            .type-p-data{
                 display: inline-block;
                 background: #001a2a;
                 height: 3vh;
                 margin-right: 20px;
                 color: #0de0ac !important;
             }
         }
     }
      }
  }
}
#manyManual{
     width: 95vw;
    height: 88vh;
     .manual-con{ 
         padding: 0 1vw;
         flex-wrap: wrap;
         margin-top: 2vh;
         .manual_list{
                   margin-top: 1vh;
      margin-left: 0.6vw;
         }
    }
}
#manyManual2{
    width: 64vw;
    height: 46vh;
    .evaluate-header{
            height: 3vh;
    line-height: 3vh;
    padding-top: 0;
        font-size: 2vh;
        .evaluate-header-icon{
                margin-top: -2vh;
                width: 1.5vw;
    height: 2vh;
        }
    }
      .manual-con{ 
         padding: 0 1vw;
         flex-wrap: wrap;
         margin-top: 2vh;
         .manual_list{
                margin-top: 1vh;
            margin-left: 0.6vw;
            .manual-con-tit{
                font-size: .8vw;
            }
         }
    }
}
#manyManual4{
     width: 64vw;
     height: 88vh;
      .manual-con{ 
         padding: 0 1vw;
         flex-wrap: wrap;
         margin-top: 2vh;
         .manual_list{
                margin-top: 1vh;
            margin-left: 0.6vw;
            .manual-con-tit{
                font-size: .8vw;
            }
         }
    } 
}


          ::v-deep{
           .manual-con-p{
               .el-slider{
                   height: 4vh;
               }
                .el-slider__runway{
             height: 1vh;
            background-color: #022537;
	        box-shadow: 0px 1px 0px 0px #d4e3e8, inset 0px 1px 0px 0px rgba(0, 0, 0, 0.08);
             border-radius:20px;
             width: 27vw;
             margin-left: 1.5vw;
             .el-slider__bar{
                  height: 1vh;
                  	background-color: #d4e3e8;
	                box-shadow: inset 0px 1px 0px 0px rgba(0, 0, 0, 0.08);
                  border-top-left-radius: 20px;
                 border-bottom-left-radius:20px
             }
             .el-slider__button-wrapper{
                 top: -13px;
             }
             @media only screen and (min-width: 1800px) {
                  .el-slider__button-wrapper{
                 top: -0.9vh !important;
             }
                }
             .el-slider__button{
                 width: 2vw;
                 height: 2vh;
                 background-image: linear-gradient(-45deg, #61f9ff 0%, #02a1dc 65%), linear-gradient(-45deg, #207295 0%, #6ff6ff 100%);
	             background-blend-mode: normal, normal;
                 border-radius: 10px;
                 border: none;
                //  margin-left: 1vw;
                //  margin-top: 1vh;
             }
         }
           }
           .manual-con-o{
               .el-slider__bar{
                  background-color: #98ecd5 !important;
                  box-shadow: inset 0px 1px 0px 0px rgba(0, 0, 0, 0.08) !important;
               }
               .el-slider__button{
                  background-image: linear-gradient(-45deg, #61f9ff 0%, #07ae75 65%), linear-gradient(-45deg, #207295 0%, #6ff6ff 100%) !important;
	              background-blend-mode: normal, normal !important;
               }
           }
           .manual-con-btn{
               justify-content: center;
               margin-top: 10px;
               
               div{
                   padding: 2px 20px;
                   border-radius: 3px;
                   text-align: center;
                   line-height: 20px;
                   cursor: pointer;
                   
               }
                .pzd{
             background:#056181 ;
                  
                   color: #fff;
                   margin-right: 10px;
               }
               .oactive{
                 background:#0ef7ff ;
                   color: #fff;
               }
           }
           .manual-con-add{
               margin-top: 2vh;
               .add-double-left{
                 background: #02264490;
                 height: 4vh;
                 width: 8vw;
                 margin-left: 10px;
                 color: #1BA3DA;
                 cursor: pointer;
                 text-align: center;
                 line-height: 4vh;
                 span{
                    color: #001a2b;
                    font-size: 2vw;
                    display:inline-block ;
                    height: 4vh;
                    line-height: 4vh;
                 }
                .el-icon-caret-left2{
                     margin-left: -10px;
                 }
                 .el-icon-caret-left1{
                     margin-right: -10px;
                 }
               }
               .add-double-right{
                   margin-right: 10px;
               }
           }
   
           .add-double-cen{
               flex-grow: 1;
               justify-content: center;
               .add-double-cen-btn{
                      background: #02264490;
                 height: 4vh;
                 width: 5vw;
                 margin-left: 20px;
                 color: #001a2b;
                 cursor: pointer;
                 text-align: center;
                  span{
                    color: #001a2b;
                    font-size: 2vw;
                    display:inline-block ;
                    height: 4vh;
                    line-height: 4vh;
                 }
               }
           }
         }
</style>